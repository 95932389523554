import * as S from './Landing.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { getCookie } from '../../../../utils/encrypt';
import { useEffect, useRef } from 'react';
import { getGtagId } from '../../../../utils/gtag';

export function Landing() {
  return <></>;

  // const { config, isShowLanding } = useGameState();
  // const iframeRef = useRef<null | HTMLIFrameElement>(null);
  //
  // const handleIframeOnload = async () => {
  //   if (iframeRef.current && iframeRef.current.contentWindow) {
  //     const userIpAddress = await fetch(`${window.location.origin}/remote-addr`)
  //       .then(async (res) => await res.json())
  //       .then((res: { ip: string }) => res.ip);
  //
  //     const gtagSessionId = await getGtagId('session_id');
  //     const gtagClientId = await getGtagId('client_id');
  //
  //     const utm_campaign = new URLSearchParams(window.location.search).get('utm_campaign');
  //
  //     const messageForIframe = {
  //       client_ip_address: userIpAddress,
  //       client_user_agent: window.navigator.userAgent,
  //       fbc: getCookie('_fbc'),
  //       fbp: getCookie('_fbp'),
  //       action_source: config.link,
  //       project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //       gtag_client_id: gtagClientId,
  //       gtag_session_id: gtagSessionId,
  //       ...(utm_campaign && { utm_campaign }),
  //     };
  //
  //     setInterval(() => {
  //       iframeRef.current?.contentWindow?.postMessage(JSON.stringify(messageForIframe), '*');
  //     }, 500);
  //   }
  // };
  //
  // useEffect(() => {
  //   window.addEventListener('message', (event) => {
  //     if (typeof event.data === 'string') {
  //       const data = JSON.parse(event.data);
  //       if (data.redirectUrl) {
  //         console.log('redirect url', data.redirectUrl);
  //         window.open(data.redirectUrl, '_self');
  //       }
  //     }
  //   });
  // }, []);
  //
  // const src = `${window.location.origin}/out/${config.useLanding}`;
  //
  // return (
  //   <S.Wrapper $isVisible={isShowLanding}>
  //     <iframe title="iframe" ref={iframeRef} onLoad={handleIframeOnload} src={src}></iframe>
  //   </S.Wrapper>
  // );
}
