import styled from 'styled-components';
import BackPng from '../../../../assets/leaderboard/background.png'
import CloseSvg from '../../../../assets/buttons/close.svg'
import CoinPng from '../../../../assets/leaderboard/coin.png'

export const Wrapper = styled.div`
  display: flex;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    align-items: end;
  }
`;

export const Modal = styled.div`
  background-color: #2D1804;
  height: 80%;
  max-width: 741px;
  width: 100%;
  position: relative;
  background-image: url(${BackPng});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;

  @media (max-width: 800px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0px;
  }
`;

export const CloseButton = styled.div<{ $isWebView: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  background-image: url(${CloseSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: all 0.1s ease-out;
  z-index: 99;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 800px) {
    top: 30px;
    right: 30px;
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;


export const Title = styled.span<{ $isWebView: boolean }>`
  color:  rgba(255, 225, 168, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 50px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
  cursor: default;
  ${props => props.$isWebView && `
    margin-top: 80px;
  `}
`;

export const SubTitle = styled.span`
  color:  white;
  cursor: default;
  font-family: 'Inknut Antiqua', serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  user-select: none;
  -webkit-user-select: none;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const TableWrapper = styled.div`
  cursor: default;
  margin: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(67, 49, 34, 1);
  }
`

export const Table = styled.div`
  font-family: 'Inknut Antiqua', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  user-select: none;
  -webkit-user-select: none;
`

export const Row = styled.div<{ $backImg?: string }>`
  padding: 15px;
  display: flex;
  border-radius: 8px;
  background: rgba(52, 30, 8, 1);
  margin: 10px;

  div:not(.coin) {
    &:first-child {
      margin-left: 10px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.5);
      min-width: 40px;
      height: 50px;
      background-image: url(${props => props.$backImg});
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:nth-child(2) {
      text-align: left;
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-left: 20px;
      @media (max-width: 380px) {
        margin-left: 0px;
      }
    }
    &:last-child {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      div {
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
  &.selected {
    div:first-child {
      color: rgba(227, 191, 126, 1);

    }
    border: 1px solid rgba(227, 191, 126, 1);
    background: linear-gradient(0deg, #45280C, #45280C),linear-gradient(0deg, #E3BF7E, #E3BF7E);
  }

  &:nth-child(1) {
    position: relative;
    &:after {
      background: rgba(151, 99, 12, 1);
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50px;
      left: 0;
    }
    div {
      &:first-child {
        color: rgba(151, 99, 12, 1);
      }
    }
  }
  &:nth-child(2) {
    position: relative;
    &:after {
      background: rgba(211, 218, 235, 1);
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50px;
      left: 0;
    }
    div {
      &:first-child {
        color: rgba(69, 74, 86, 1);
      }
    }
  }
  &:nth-child(3) {
    position: relative;
    &:after {
      background: rgba(208, 136, 68, 1);
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50px;
      left: 0;
    }
    div {
      &:first-child {
        color: rgba(69, 74, 86, 1);
      }
    }
  }
`


export const Coin = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${CoinPng});
  background-size: cover;
`;


export const TableHeader = styled.div`
  cursor: default;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Inknut Antiqua', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  div {
    padding: 10px;
    &:nth-child(1) {
      margin: 0 10px;
      width: 60px;
    }
    &:nth-child(2) {
      text-align: left;
      flex-grow: 1;
    }
    &:nth-child(3) {
      margin-right: 25px;
    }
  }
`


