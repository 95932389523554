import * as S from './AgeAccept.styles';

export function AgeAccept({ onClose }: { onClose: () => void }) {
  return (
    <S.Wrapper>
      <S.Modal>
        <S.Title>ARE YOU OVER 18 YEARS OLD?</S.Title>
        <S.SubTitle>To continue, confirm that you have reached the legal age</S.SubTitle>
        <S.ButtonsWrapper>
          <S.DeclineButton onClick={() => {}}>
            <S.ButtonText>I am under 18</S.ButtonText>
          </S.DeclineButton>
          <S.AcceptButton onClick={onClose}>
            <S.ButtonText>I am over 18</S.ButtonText>
          </S.AcceptButton>
        </S.ButtonsWrapper>
      </S.Modal>
    </S.Wrapper>
  );
}
