import { useEffect } from 'react';
import Game from './components/Game';
import { GameProvider } from './providers/GameProvider';
import { ConfigType } from './providers/GameProvider.types';

function App({ config }: { config: ConfigType }) {
  console.log('0.2');
  //
  // useEffect(() => {
  //   const facebookAndGoogleScript = document.createElement('script');
  //   const noscript = document.createElement('noscript');
  //   const googleScript = document.createElement('script');
  //   googleScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_EVENTS_ID}`;
  //
  //   facebookAndGoogleScript.id = 'fb-pixel/google-analytics';
  //   facebookAndGoogleScript.type = 'text/javascript';
  //
  //   facebookAndGoogleScript.innerHTML = `
  //     !function (f, b, e, v, n, t, s) {
  //       if (f.fbq) return; n = f.fbq = function () {
  //         n.callMethod ?
  //         n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  //       };
  //       if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  //       n.queue = []; t = b.createElement(e); t.async = !0;
  //       t.src = v; s = b.getElementsByTagName(e)[0];
  //       s.parentNode.insertBefore(t, s)
  //     }(window, document, 'script',
  //       'https://connect.facebook.net/en_US/fbevents.js');
  //     fbq('init', ${process.env.REACT_APP_PIXEL_ID});
  //     fbq('track', 'PageView');
  //
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());
  //     gtag('config', '${process.env.REACT_APP_GTAG_EVENTS_ID}');
  //     `;
  //
  //   noscript.innerHTML = `<img
  //               height="1"
  //               width="1"
  //               style={{ display: 'none' }}
  //               src="https://www.facebook.com/tr?id=${process.env.REACT_APP_PIXEL_ID}&ev=PageView&noscript=1"
  //           />`;
  //
  //   const googleDataLayerScript = document.createElement('script');
  //   googleDataLayerScript.innerHTML = `(function (w, d, s, l, i) {
  //             w[l] = w[l] || []; w[l].push({
  //               'gtm.start':
  //                 new Date().getTime(), event: 'gtm.js'
  //             }); var f = d.getElementsByTagName(s)[0],
  //               j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
  //                 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  //           })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GOOGLE_TAG_ID}')`;
  //
  //   if (process.env.REACT_APP_GOOGLE_TAG_ID) {
  //     document.body.append(googleDataLayerScript);
  //   }
  //
  //   document.body.append(googleScript);
  //   document.body.append(facebookAndGoogleScript);
  //   document.body.append(noscript);
  // }, []);

  return (
    <GameProvider config={config}>
      <Game />
    </GameProvider>
  );
}

export default App;
