import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import GameMachineBg from '../src/assets/gameMachine/background.webp';
import IconsPng from '../src/assets/gameMachine/icons_sprite_2x.webp';

import SpinButtonPng from '../src/assets/buttons/button.png';
import RedButtonPng from '../src/assets/buttons/red_button.webp';
import SpinActiveButtonPng from '../src/assets/buttons/active_button.png';
import ScoreBgPng from '../src/assets/gameMachine/score_bg.png';

import CupPng from '../src/assets/buttons/cup.png';
import HelpButtonPng from '../src/assets/buttons/help_button.png';
import { DEFAULT_CONFIG } from './providers/GameProvider.types';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const IMAGES_TO_LOAD = [
  GameMachineBg,
  IconsPng,
  SpinButtonPng,
  RedButtonPng,
  SpinActiveButtonPng,
  ScoreBgPng,
  CupPng,
  HelpButtonPng,
];

Promise.all([
  ...IMAGES_TO_LOAD.map((imageSrc) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();
      loadImg.src = imageSrc;
      loadImg.onload = () => resolve(imageSrc);
      loadImg.onerror = (err) => reject(err);
    });
  }),
])
  .then((allPromises) => {
    //@ts-ignore
    window.webkit?.messageHandlers?.handler?.postMessage('loadFinished');

    root.render(<App config={DEFAULT_CONFIG} />);
  })
  .catch((err) => console.log('Failed to load', err));
