import styled from 'styled-components';
import ButtonPng from '../../../../assets/buttons/button.png'
import ActiveButtonPng from '../../../../assets/buttons/active_button.png'
import ScoreBgPng from '../../../../assets/gameMachine/score_bg.png'
import IconsSprite2xPng from '../../../../assets/gameMachine/icons_sprite_2x.webp'
import ScreenBgPng from '../../../../assets/gameMachine/screen_bg.png';

import BackgroundPng from '../../../../assets/gameMachine/background.webp';

export const GameMachine = styled.div`
background-image: url(${BackgroundPng});
background-size: 1053px 1080px;
background-repeat: no-repeat;
background-position: center;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
z-index: 5;

@media (max-height: 1100px) {
  background-position: bottom 30px center;
  background-size: 895px 918px;
}
@media (max-width: 800px) or (max-height: 1100px) {
  background-size: 895px 918px;
}
@media (max-width: 800px) {
  background-position: bottom 10px center;
}

@media (max-height: 650px) and (max-width: 800px) {
  background-size: 737px 756px;
  background-position: bottom 40px center;
}

&:before {
  position: absolute;
  content: '';
  display: block;
  width: 1070px;
  height: 1080px;
  bottom: calc(50% - 540px);
  left: calc(50% - 530px);
  box-shadow: 0px 0px 40px 70px rgba(27, 13, 9, 1) inset;
  @media (max-width: 800px) or (max-height: 1100px) {
    width: 900px;
    height: 920px;
    left: calc(50% - 450px);
    bottom: 0px;
  }
}

&:after {
  background: linear-gradient(180deg, #0B0500 0%, rgba(11, 5, 0, 0.5) 49.48%, rgba(67, 35, 8, 0) 100%, rgba(11, 5, 0, 0) 100%);
  position: absolute;
  content: '';
  display: block;
  width: 534px;
  height: 130px;
  bottom: calc(50% - 410px);
  left: calc(50% - 267px);
  @media (max-width: 800px) or (max-height: 1100px) {
    width: 453px;
    height: 110px;
    left: calc(50% - 226px);
    bottom: 132px;
  }
  @media (max-width: 800px) {
    bottom: 112px;
  }
  @media (max-height: 650px) and (max-width: 800px) {
    bottom: 105px;
  }
}
`;

export const SpinButton = styled.button`
  background: transparent;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: calc(50% + 310px);
  left: calc(50% - 155px);
  height: 86px;
  width: 311px;
  background-image: url(${ButtonPng});
  background-size: cover;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 55;
  user-select: none;
  -webkit-user-select: none;
  transition: all 0.5s ease-out;

  @media (pointer: fine) {
    &:hover {
      &:after {
        background: radial-gradient(closest-side,rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
      }
      span {
        color: #FCD58C;
      }
    }
  }

  &:active {
    background-image: url(${ActiveButtonPng});
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
    span {
      color: #FFE1A8;
      text-shadow: none;
    }
  }

  &:disabled {
    pointer-events: none;
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
    span {
      color: rgba(255, 225, 168, 0.5);
      text-shadow: none;
    }
  }

  @media (max-width: 800px) or (max-height: 1100px)  {
    height: 68px;
    width: 250px;
    top: inherit;
    left: calc(50% - 125px);
    bottom: calc(50% - 330px);
  }

  @media (max-height: 1100px) {
    top: inherit;
    bottom: 160px;
  }
  @media (max-width: 800px) {
    bottom: 140px;
  }

  @media (max-height: 650px) and (max-width: 800px) {
    bottom: 130px;
  }
`;


export const ButtonText = styled.span`
  transition: all 0.2s linear;
  color: rgba(255, 225, 168, 1);
  text-shadow: 4px 4px 4px rgba(25, 46, 51, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  text-transform: uppercase;
  @media (max-width: 800px) or (max-height: 1100px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SpinnerWrapper = styled.div<{ $isWithShadow: boolean }>`
  height: 360px;
  width: 350px;
  background-image: url(${ScreenBgPng});
  background-size: cover;
  position: absolute;
  left: calc(50% - 175px);
  bottom: calc(50% - 240px);
  z-index: 2;
  overflow: hidden;
  display: flex;

  @media (max-width: 800px) {
    transform: scale(0.85);
    bottom: calc(50% - 235px);
  }
  @media (max-height: 1100px) {
    transform: scale(0.85);
    bottom: 255px;
  }
  @media (max-width: 800px) {
    bottom: 235px;
  }
  @media (max-height: 650px) and (max-width: 800px) {
    transform: scale(0.7);
    bottom: 195px;
  }
  &:after {
    transition: all 0.5s linear;
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 5;
    ${props => props.$isWithShadow && `
      box-shadow: 0px -140px 20px -15px rgba(27, 13, 9, 1) inset, 0px 140px 20px -15px rgba(27, 13, 9, 1) inset;
    `}
  }
`;

export const SpinnerContainer = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
`


export const Icons = styled.div<{ $seconds: number, $isWinningRound: boolean }>`
  box-sizing: border-box;
  display: inline-block;
  height: 954px;
  width: 92px;
  background: url(${IconsSprite2xPng}) repeat-y;
  background-size: cover;
  will-change: backgroundPosition;
  transition: ${props => props.$seconds}s background-position cubic-bezier(.41,-0.01,.43,1.09);

  background-position: 2px;
  margin: 0 7px;

  ${props => props.$isWinningRound && `
    animation: fadeIn 0.7s alternate infinite ease-out;
  `}
  @keyframes fadeIn {
    0%   {transform: scale(1);}
    100% {transform: scale(1.15);}
  }
}
`

export const WinAmountWrapper = styled.div<{ $isWinningRound: boolean }>`
  cursor: default;
  background: url(${ScoreBgPng});
  background-size: cover;
  width: 260px;
  height: 72px;
  position: absolute;
  bottom: calc(50% + 90px);
  left: calc(50% - 130px);
  z-index: 55;
  text-align: center;

  @media (max-width: 800px) {
    width: 215px;
    height: 60px;
    bottom: calc(50% + 70px);
    left: calc(50% - 107px);
  }
  @media (max-height: 1100px) {
    width: 215px;
    height: 60px;
    bottom: 565px;
    left: calc(50% - 107px);
  }
  @media (max-width: 800px) {
    bottom: 545px;
  }
  @media (max-height: 650px) and (max-width: 800px) {
    bottom: 475px;
  }
  ${props => props.$isWinningRound && `
    filter: drop-shadow(0 0 15px rgba(253, 125, 7, 1));
  `}
`
export const WinAmount = styled.div`
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  color:  rgba(255, 225, 168, 1);
  -webkit-text-stroke:  1px rgba(67, 39, 23, 1);
  text-stroke: 1px rgba(67, 39, 23, 1);
  margin-top: 10px;
  @media (max-height: 1100px) {
    font-size: 28px;
    line-height: 34px;
    margin-top: 6px;
  }
  @media (max-width: 800px) {
    margin-top: 8px;
  }
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
`

export const TermsWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 54;
  bottom: 0px;
  padding: 5px 0;
  background: linear-gradient(0deg, #1b0d09 90%, transparent 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    background: linear-gradient(0deg, #0B0500 70%, transparent 100%);
  }
`
export const TermsText = styled.p`
  max-width: 800px;
  color: #996F5C;
  text-align: center;
  font-family: "Inknut Antiqua";
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0 10px 5px;
  max-height: 60px;
  overflow: auto;
  text-shadow: #070103 0px 0px 20px;
  @media (max-width: 800px){
    font-size: 7px;
  }

  a {
    cursor: pointer;
    color: #E5D2AB;
    text-align: center;
    font-family: "Inknut Antiqua";
    font-style: normal;
    font-weight: 300;
    text-decoration-line: none;
  }
`
export const TermsBottomText = styled.p`
  color: #996F5C;
  text-align: center;
  font-family: "Inknut Antiqua";
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  @media (max-width: 800px){
    font-size: 7px;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 5px;
  justify-content: center;
`
export const Link = styled.span`
  cursor: pointer;
  color: #E5D2AB;
  text-align: center;
  font-family: "Inknut Antiqua";
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-decoration-line: underline;
  @media (max-width: 800px){
    font-size: 7px;
  }
`

