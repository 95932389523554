import { useEffect, useRef, useState } from 'react';
import { useGameState } from '../../providers/GameProvider.hooks';
import GameMachine from './components';
import Modal from './components/Modal';
import Landing from './components/Landing';

import * as S from './Game.styles';
import Leaderboard from './components/Leaderboard';
import { numberWithSpaces } from '../../utils/number';
import ModalBackPng from '../../assets/modal/background.png';
import ButtonPng from '../../assets/modal/button.png';
import LeaderboardBackPng from '../../assets/leaderboard/background.png';
import Leader1 from '../../assets/leaderboard/leader_1.png';
import Leader2 from '../../assets/leaderboard/leader_2.png';
import Leader3 from '../../assets/leaderboard/leader_3.png';
import HowToPlay from './components/HowToPlay';
import CookiesAccept from './components/CookiesAccept';
import { isShowTermsAndPrivacy } from '../../utils/routes';
import AgeAccept from './components/AgeAccept';

function App() {
  const { totalWinAmount, isWinningRound, roundWinAmount, config, isShowLanding } = useGameState();
  const [isShowLeaderboard, setShowLeaderboard] = useState(false);
  const [isShowHowToPlay, setShowHowToPlay] = useState(false);
  const [isCookiesAccepted, setCookiesAccepted] = useState(window.localStorage.getItem('isCookiesAccepted') === 'true');
  const [isAgeAccepted, setAgeAccepted] = useState(window.localStorage.getItem('isAgeAccepted') === 'true');
  const [showVideo, setShowVideo] = useState(false);
  const [showWinAnimation, setShowWinAnimation] = useState(false);
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const params = new URLSearchParams(window.location.search);
  const isWebView = params.get('webView') === 'true';

  useEffect(() => {
    if (isWinningRound) {
      setShowVideo(true);
      setShowWinAnimation(true);
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
      setTimeout(() => {
        setShowVideo(false);
      }, 2800);
      setTimeout(() => {
        setShowWinAnimation(false);
      }, 2500);
    }
  }, [isWinningRound]);

  return (
    <S.AppWrapper>
      <S.HelpButton
        $isWebView={isWebView}
        onClick={() => {
          setShowHowToPlay(true);
        }}
      >
        <S.HelpButtonText>?</S.HelpButtonText>
      </S.HelpButton>
      <S.Score $isWebView={isWebView}>
        <S.ScoreText>{numberWithSpaces(totalWinAmount)}</S.ScoreText>
      </S.Score>
      <S.LeaderboardButton
        $isWebView={isWebView}
        onClick={() => {
          setShowLeaderboard(true);
        }}
      />

      <S.GameMachineWrapper>
        <GameMachine></GameMachine>
      </S.GameMachineWrapper>

      <Modal />
      {isShowLanding && <Landing />}

      {isShowLeaderboard && (
        <Leaderboard
          isWebView={isWebView}
          onClose={() => {
            setShowLeaderboard(false);
          }}
        />
      )}

      {isShowHowToPlay && (
        <HowToPlay
          isWebView={isWebView}
          onClose={() => {
            setShowHowToPlay(false);
          }}
        />
      )}

      {!isCookiesAccepted && isShowTermsAndPrivacy && (
        <CookiesAccept
          onClose={() => {
            window.localStorage.setItem('isCookiesAccepted', 'true');
            setCookiesAccepted(true);
          }}
        />
      )}

      {!isAgeAccepted && isCookiesAccepted && (
        <AgeAccept
          onClose={() => {
            window.localStorage.setItem('isAgeAccepted', 'true');
            setAgeAccepted(true);
          }}
        />
      )}

      <S.VideoWrapper $showVideo={showVideo}>
        <video autoPlay ref={videoRef} muted playsInline preload="auto">
          {window.innerWidth > 450 ? (
            <>
              <source
                src={`${window.location.origin}/video_mp4/web_masked.mp4`}
                type='video/mp4; codecs="hvc1"'
              ></source>
              <source src={`${window.location.origin}/video_webm/web_masked.webm`} type="video/webm"></source>
            </>
          ) : (
            <>
              <source
                src={`${window.location.origin}/video_mp4/mobile_masked.mp4`}
                type='video/mp4; codecs="hvc1"'
              ></source>
              <source src={`${window.location.origin}/video_webm/mobile_masked.webm`} type="video/webm"></source>
            </>
          )}
        </video>
      </S.VideoWrapper>

      <S.WinAnimation $showWinAnimation={showWinAnimation}>
        <div>
          <p>{config.winAnimationTitle}</p>
          <p>{roundWinAmount && numberWithSpaces(roundWinAmount)}</p>
        </div>
      </S.WinAnimation>

      <img src={ModalBackPng} width={0} height={0} alt="preload"></img>
      <img src={ButtonPng} width={0} height={0} alt="preload"></img>
      <img src={LeaderboardBackPng} width={0} height={0} alt="preload"></img>
      <img src={Leader1} width={0} height={0} alt="preload"></img>
      <img src={Leader2} width={0} height={0} alt="preload"></img>
      <img src={Leader3} width={0} height={0} alt="preload"></img>
    </S.AppWrapper>
  );
}

export default App;
